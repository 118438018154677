<template>
  <v-container 
    fill-height 
    fluid
  >
    <v-row align="center"
      justify="center"
    >
      <v-col>
        <v-flex d-flex>
          <v-layout wrap> 
            <v-flex 
              md4
            >        
            </v-flex>
            <v-flex 
              md4
              pa-2         
            >
              <v-card style="background: #ffffff;">
                <v-card-title>
            <v-img
              alt="Metricforms"
              class="shrink mr-2"
              contain
              src="@/assets/logo.png"
            ></v-img>
                </v-card-title>                       
                <v-card-text>
                  <v-form>
                    <v-text-field 
                      ref="email"
                      v-model="email"
                      prepend-icon="mdi-account"
                      label="Username or Email"
                      placeholder=" "
                      persistent-placeholder                        
                    ></v-text-field>                
                    <v-text-field 
                      v-model="password"
                      prepend-icon="mdi-lock"
                      label="Password" 
                      type="password"
                      placeholder=" "
                      persistent-placeholder                        
                    ></v-text-field>
                    <v-card-actions>                 
                      <v-btn 
                        primary block
                        @click="signIn()"
                      >
                        Sign In
                      </v-btn>                                                            
                    </v-card-actions>
                  </v-form>
                </v-card-text>
              </v-card>
    <v-row>
      <v-col>
      <v-alert
        v-model="error"
        border="left"
        dense
        close-text="Close Alert"
        type="error"
        dark
      >
        {{errorMessage}}
      </v-alert> 
      </v-col>
    </v-row>                 
            </v-flex>
            <v-flex 
              md4
            >        
            </v-flex>
          </v-layout>
        </v-flex>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import errorUtils from '@/utils/errorUtils'
  import { RepositoryFactory } from '@/repositories/RepositoryFactory'

  const UserRepository = RepositoryFactory.get('user');

  export default {
    name: 'SignIn',
    
    data: () => ({
      email: '',
      password: ''
    }),

  computed: {
    error () {
      return errorUtils.hasError();
    },
    errorMessage () {
      return errorUtils.errorMessage();
    }   
  },

    methods: {

      async signIn() {      
        const { data } = await UserRepository.authenticate(this.email, this.password);
          if (data.success) {               
            this.$store.commit('signIn', data.token); 
            this.$router.push("mycourses");
          } else {
            errorUtils.showError(data.message);
          }
      },

    },



  }
</script>

